const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;

export async function storePresentation(background_id, formData) {
  formData.append("presentation[background_id]", background_id);

  await fetch(`${window.location.pathname}/presentations`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": CSRF_TOKEN,
    },
    body: formData,
  });
}

// drawings: serialization (string) of the drawings
export async function storeDrawings(background_id, drawings) {
  const body = {
    presentation: {
      background_id: background_id,
      drawings: drawings,
    },
  };
  await fetch(`${window.location.pathname}/presentations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": CSRF_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function storePosition(position_id, params) {
  const body = {
    picture_position: {
      ...params,
    },
  };
  await fetch(`/supervisor/picture_positions/${position_id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": CSRF_TOKEN,
    },
    body: JSON.stringify(body),
  });
}
