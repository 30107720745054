const tinymceConfig = (limited = false, plugins = [], imageConfig = {}) => {
  const content_css = document.querySelector(
    "meta[name=editor-css-path]",
  )?.content;

  const config = {
    // Remove some default look and feel
    skin: false,
    branding: false,

    // Make sure editor looks like public frontend
    content_css,
    body_class: "prose editor",
    height: 300,

    // Reset menus
    menubar: false,
    toolbar: constructToolbar(limited, plugins),
    block_formats: "Paragraph=p; Header 1=h2; Header 2=h3; Header 3=h4;",
    custom_colors: false,

    styles: {
      ...headings,
      ...text,
      ...tables,
      ...alignment,
    },

    // Prevent tinymce converting our absolute urls to relative format
    document_base_url: window.location.origin,
    relative_urls: false,
    convert_urls: false,

    plugins,
  };

  if (plugins.includes("anchor")) {
    /* Nothing yet - just here for clarity */
  }

  if (plugins.includes("image")) {
    config.automatic_uploads = true;

    config.images_upload_handler = imageConfig.imageUploadHandler;
    config.images_upload_url = imageConfig.imageUploadURL;
    config.image_uploadtab = true;
  }

  if (plugins.includes("link")) {
    config.link_class_list = [{ title: "Default", value: "prose__link" }];
  }

  if (plugins.includes("lists")) {
    /* Nothing yet - but here for clarity */
  }

  if (plugins.includes("table")) {
    config.table_appearance_options = false;
    config.table_default_styles = {};
    config.table_size_mode = "responsive";
  }

  if (plugins.includes("wordcount")) {
    /* Nothing yet - just here for clarity */
  }

  return config;
};

function constructToolbar(limited, plugins = []) {
  const edit = ["undo", "redo"];
  const format = limited ? [] : ["blocks"];
  const emphasis = ["bold", "italic", "removeformat"];
  const alignment = limited ? [] : ["alignleft", "aligncenter", "alignright"];
  const meta = [];
  const lists = limited ? [] : ["outdent", "indent"];
  const table = [];

  if (plugins.includes("link")) meta.push("link");

  if (plugins.includes("anchor")) meta.push("anchor");

  if (plugins.includes("image")) meta.push("image");

  if (plugins.includes("lists")) lists.push("bullist", "numlist");

  if (plugins.includes("table")) table.push("table");

  const toolbarParts = [edit, format, emphasis, alignment, meta, lists, table];

  return toolbarParts
    .filter((a) => a.length)
    .map((p) => p.join(" "))
    .join(" | ");
}

/* Define all formats */
const headings = {
  h2: [{ block: "h2", classes: "" }],
  h3: [{ block: "h3", classes: "" }],
  h4: [{ block: "h4", classes: "" }],
};

const text = {
  p: [{ block: "p", classes: "" }],
  bold: [{ inline: "strong", classes: "prose--bold" }],
  italic: [{ inline: "em", classes: "prose--italic" }],
  underline: [
    {
      inline: "span",
      classes: "prose--underline",
    },
  ],
  link: [
    {
      inline: "a",
      selector: "a",
      classes: "prose__link",
    },
  ],
};

const tables = {
  table: [{ block: "table", classes: "prose__table table" }],
};

const alignment = {
  alignleft: [
    {
      selector: "p,h1,h2,h3,h4,h5,h6,div,ul,ol,li",
      classes: "prose--left",
    },
  ],
  alignCenter: [
    {
      selector: "p,h1,h2,h3,h4,h5,h6,div,ul,ol,li",
      classes: "prose--center",
    },
  ],
  alignRight: [
    {
      selector: "p,h1,h2,h3,h4,h5,h6,div,ul,ol,li",
      classes: "prose--right",
    },
  ],
};

export default tinymceConfig;
