import tinyMCE from "tinymce/tinymce";
// Import DOM model
import "tinymce/models/dom";
import "tinymce/themes/silver";

/* We have to import the plugins so that tinymce doesn't start looking for them in a separate path */
import "tinymce/plugins/anchor";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/table";
import "tinymce/plugins/wordcount";

/* Import our config */
import tinymceConfig from "../config/tinymce_config";
import "tinymce/themes/silver/index";
import "tinymce/icons/default/index";

export default function tinymce({ limited = false }) {
  return {
    id: null,
    // Default plugins
    plugins: ["lists", "link", "anchor", "wordcount"],
    imageUploadURL: "",

    init() {
      this.id = `#${this.$root.getAttribute("id")}`;

      // Add extra settings and plugins
      if (!limited && this.$root.hasAttribute("data-image-plugin")) {
        this.plugins.push("image");
        this.imageUploadURL = this.$root.getAttribute("data-image-upload-url");
      }

      if (!limited && this.$root.hasAttribute("data-table-plugin")) {
        this.plugins.push("table");
      }

      // Since we use turbo/unpoly for page requests
      // We need to re-initialize tinyMCE every time it re-appears on a page
      tinyMCE.remove(this.id);

      tinyMCE.init({
        selector: this.id,
        readonly: this.$root.disabled,
        ...tinymceConfig(limited, this.plugins, {
          imageUploadURL: this.imageUploadURL,
          imageUploadHandler: (b, s, f, p) =>
            this.imageUploadHandler(b, s, f, p),
        }),
      });
    },

    // This function also takes "progress" as the last param - but we have not implemented that
    async imageUploadHandler(blobInfo, success, failure) {
      const token = document
        .querySelector("meta[name=csrf-token]")
        ?.getAttribute("content");

      const file = blobInfo.blob();

      if (file.size > process.env.MAX_UPLOAD_SIZE) {
        failure(
          "This file is too large. You can only upload files smaller than 1MiB",
          { remove: true },
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", blobInfo.blob());

      const request = new Request(this.imageUploadURL, {
        method: "POST",
        headers: {
          "X-CSRF-Token": token,
        },
        body: formData,
      });

      try {
        const response = await fetch(request);
        const json = await response.json();
        success(json.location);
      } catch (e) {
        failure(e, { remove: true });
      }
    },
  };
}
