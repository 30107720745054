// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "../stylesheets/admin.css";

import Alpine from "alpinejs";
import { alpineComponents } from "@tree-company/admin-components";
import { tinymce } from "../components";

document.addEventListener("DOMContentLoaded", () => {
  window.Alpine = Alpine;
  Alpine.data("tinymce", tinymce);
  Alpine.start();
});

document.addEventListener("alpine:init", () => {
  Object.entries(alpineComponents).forEach(([name, component]) => {
    Alpine.data(name, component);
  });
});
